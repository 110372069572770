import React, { Component } from 'react';
import { connect, } from 'react-redux';
import { CloseButton, Container, Modal, Spinner, Placeholder, PlaceholderButton, Card } from 'react-bootstrap';
import { withRouter } from './withRouter';
import { Helmet } from 'react-helmet';
import Help from "../components/Help";

import GameList from "constants/GameList";
import GamePackList from "constants/GamePackList";
import GameAddonList from "constants/GameAddonList";
import styles from './ShopSingleStyles.module.scss'
import Game from './Games/Game';
import { addToCart } from 'actions/cart';
import Reviews from "constants/reviews";

// Slick Carousel
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./carouselStyles.css";
import Slider from "react-slick";
import StarFull from "images/template/full-star.png";
import StarEmpty from "images/template/empty-star.png";
import GiftBox from "images/Promotions/02642 Christmas Present.png";
// Lottie animations
import Lottie from 'react-lottie'
import time from 'animations/time.gif'
import players from 'animations/players.gif'
import age from 'animations/age.gif'

import placeHolderDesktop from 'images/gameStorePreviews/placeholder-desktop.png';
import placeHolderMobile from 'images/gameStorePreviews/placeholder-mobile.png';


const howItWorksBlocks = [
    {
        number: 1,
        title: "GET IT ON THE BIG SCEEN",
        //"description": "Only one person needs to own the games. They will be 'party leader' and it's their job to get Game.City up on the biggest screen they can. Use Teams, Zoom or Discord if you want to play online. Use a smart TV to open it with a browser or connect up with a laptop and HDMI. Once you have achieved that, simply log in to your account."
        "description": "Open Game.City on a TV or connect a laptop. Log in!"
    },
    {
        number: 2,
        title: "GATHER SOME FRIENDS",
        //description: "Gather some friends, either online or in the same room. Once you have Game.City on the screen and you're logged in, players simply need to scan the QR code to join in, or head to Game.City and join using the Room Code.",
        description: "Scan the QR code or enter the Room Code at Game.City.",
    },
    {
        number: 3,
        title: "PLAY AND HAVE FUN",
        //description: "Once joined, choose a potato avatar, change your nickname and then choose a game. Get playing and have a great time. All our online games are extra family friendly so everyone can enjoy!"
        description: "Choose an avatar, pick a game, and enjoy!"
    }
];

class ShopSingle extends Component {
    static displayName = ShopSingle.name;

    constructor(props) {
        super(props);

        this.state = {
            showHowToPlay: false,
            isLoading: true,
        };
    }

    carouselSettings = {
        dots: true,
        dotsClass: "slick-dots slick-thumb",
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: true,
        centerMode: true,
        // nextArrow: <SampleNextArrow />,
        // prevArrow: <SamplePrevArrow />,
        customPaging: function (i) {
            return (
                <div className="carousel-dot">

                </div>
            );
        },
    };

    componentDidMount() {
        window.scrollTo(0, 0);
        if (this.props.game) {
            const images = [
                this.props.game.meta.storeBackground,
                this.props.game.meta.storeLogo,
                this.props.game.meta.previewImages.mobile1,
                this.props.game.meta.previewImages.desktop1,
                this.props.game.meta.previewImages.desktop2,
                this.props.game.meta.previewImages.mobile2,
            ];
            Promise.all(images.map(image => {
                return new Promise((resolve, reject) => {
                    const img = new Image();
                    img.onload = resolve;
                    img.onerror = reject;
                    img.src = image;
                });
            })).then(() => {
                setTimeout(() => {
                    this.setState({ isLoading: false });
                }, 100);
            }).catch(error => {
                // if there's an error, we still want to show the app, so set isLoading to false
                this.setState({ isLoading: false });
            });
        } else {
            this.props.navigate("/shop");
        }
    }

    getStoreBackground() {
        return {
            backgroundColor: this.props.game?.meta.storeBackgroundColour,
            backgroundImage: `url(${this.props.game?.meta.storeBackground})`,
        }
    }

    getStoreAccentBorder() {
        return {
            backgroundColor: this.props.game?.meta.storeAccentColour,
            border: `10px solid ${this.props.game?.meta.storeAccentColour}`
        }
    }

    getStoreAccentBackground() {
        return {
            backgroundColor: this.props.game?.meta.storeAccentColour,
            border: `10px solid ${this.props.game?.meta.storeAccentColour}`
        }
    }

    getStoreAccentImageBorder() {
        return {
            border: `2px solid ${this.props.game?.meta.storeAccentColour}`
        }
    }

    getStoreInfoBackground() {
        return {
            backgroundColor: `${this.props.game?.meta.storeInfoBackground}`
        }
    }

    addGameToCart(game) {
        this.props.addToCart(game);
    }

    getPrice() {
        //let price = this.props.pricing.prices?.find((x) => x.productId == this.props.game.productId);
        let countryCode = this.props.pricing.countryCode;

        if (countryCode == "GB") {
            return this.props.game?.pricing.gb.display;
        } else {
            return this.props.game?.pricing.us.display;
        }
    }

    closeHelpModal = () => {
        this.setState({ showHowToPlay: false, });
    }

    goToAddon(g){
        this.props.navigate(g.storeSlug)
    }

    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <title>Game City - {this.props.game?.name}</title>
                    <link rel="canonical" href={`https://game.city/${this.props.game?.storeSlug}`} />
                    <meta name="description" content={this.props.game?.description} />
                </Helmet>
                <div className={styles.pageShopSingle} style={this.getStoreBackground()}>
                    <section className={`${styles.band}`}>
                        <Container className="text-center">
                            <div className='row'>
                                <div className='offset-xl-1 col-xl-10'>
                                    {/*<div className={styles.backButton}>*/}
                                    {/*    <button onClick={() => this.props.navigate("/shop")} className={`${styles.button} ${styles.alternate} ${styles.small}`} style={{ width: "fit-content" }}><BiLeftArrowAlt /> Back</button>*/}
                                    {/*</div>*/}
                                    <div className={styles.gameLogo}>
                                        <img src={this.props.game?.meta.storeLogo} className={`${styles.storeLogo} ${this.props.game?.meta.storeLogo2 && styles.hasSecond}`} alt={`${this.props.game.name} Logo`} />
                                        {this.props.game?.meta.storeLogo2 && <img src={this.props.game?.meta.storeLogo2} className={styles.storeLogo2} alt={`${this.props.game.name} logo`}></img>}
                                    </div>
                                    {/* {console.log(this.state.game.gameInfo?.minAge)} */}
                                    <div className='row'>
                                        <div className='col-lg-8 order-2 order-lg-1 text-center'>
                                            <div className={`${styles.previewImages} d-none d-lg-block`} style={{ ...this.getStoreAccentBorder(), ...this.getStoreAccentBackground() }}>
                                                <div className={styles.imageRow}>
                                                    <div style={this.getStoreAccentImageBorder()}>
                                                        <img className={styles.mobilePreview} src={this.state.isLoading ? placeHolderMobile : this.props.game.meta?.previewImages?.mobile1}></img>
                                                    </div>
                                                    <div style={this.getStoreAccentImageBorder()}>
                                                        <img className={styles.desktopPreview} src={this.state.isLoading ? placeHolderDesktop : this.props.game.meta?.previewImages?.desktop1}></img>
                                                    </div>
                                                </div>
                                                <div className={styles.imageRow}>
                                                    <div style={this.getStoreAccentImageBorder()}>
                                                        <img className={styles.desktopPreview} src={this.state.isLoading ? placeHolderDesktop : this.props.game.meta?.previewImages?.desktop2}></img>
                                                    </div>
                                                    <div style={this.getStoreAccentImageBorder()}>
                                                        <img className={styles.mobilePreview} src={this.state.isLoading ? placeHolderMobile : this.props.game.meta?.previewImages?.mobile2}></img>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className={`${styles.previewImages} ${styles.mobile} d-block d-lg-none`} style={this.getStoreAccentBorder()}>
                                                <div className={`${styles.imageRow} ${styles.singleDesktopRow}`}>
                                                    <div style={this.getStoreAccentImageBorder()}>
                                                        <img src={this.state.isLoading ? placeHolderDesktop : this.props.game.meta?.previewImages?.desktop1}></img>
                                                    </div>
                                                </div>
                                                <div className={`${styles.imageRow} ${styles.singleDesktopRow}`}>
                                                    <div style={this.getStoreAccentImageBorder()}>
                                                        <img src={this.state.isLoading ? placeHolderDesktop : this.props.game.meta?.previewImages?.desktop2}></img>
                                                    </div>
                                                </div>
                                                <div className={`${styles.imageRow} ${styles.doubleColRow}`}>
                                                    <div style={this.getStoreAccentImageBorder()}>
                                                        <img src={this.state.isLoading ? placeHolderMobile : this.props.game.meta?.previewImages?.mobile1}></img>
                                                    </div>
                                                    <div style={this.getStoreAccentImageBorder()}>
                                                        <img src={this.state.isLoading ? placeHolderMobile : this.props.game.meta?.previewImages?.mobile2}></img>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                        <div className='col-lg-4 order-1 order-lg-2'>
                                            <div className={styles.gameInfoWrapper} style={this.getStoreInfoBackground()}>
                                                {/*<div className={styles.giftHint} >*/}
                                                {/*    <img src={GiftBox} alt="gift" />*/}
                                                {/*    <p>PERFECT STOCKING FILLER</p>*/}
                                                {/*    <img src={GiftBox} alt="gift" />*/}
                                                {/*</div>*/}
                                                <p className={styles.gamePrice}><strong>{this.getPrice() || <Spinner animation="border" />}</strong></p>

                                                <button className={styles.button} onClick={() => this.addGameToCart(this.props.game)}>{this.state.loading ? <Spinner animation="border" /> : "Add To Basket"}</button>
                                                {/*<br />*/}
                                                {/*<button className={`${styles.button} ${styles.small} alternate}`} onClick={() => this.setState({ showHowToPlay: true, })}>{this.state.loading ? <Spinner animation="border" /> : "How To Play"}</button>*/}

                                                <ul className={styles.gameInfo}>
                                                    <li>
                                                        <div className={styles.icon}>
                                                            <img src={time} />
                                                        </div>
                                                        <span>{this.props.game.gameInfo?.avgRoundLengthText}</span>
                                                    </li>
                                                    <li>
                                                        <div className={styles.icon}>
                                                            <img src={players} />
                                                        </div>
                                                        <span>{this.props.game.gameInfo?.minPlayers}-8 Players</span>
                                                    </li>
                                                    <li>
                                                        <div className={styles.icon}>
                                                            <img src={age} />
                                                        </div>
                                                        <span>
                                                            Age {this.props.game.gameInfo?.minAge}+
                                                        </span>
                                                    </li>
                                                </ul>

                                                <hr></hr>

                                                <ul className={styles.gameDescription}>
                                                    {this.props.game.gameInfo?.descriptions.map(description => {
                                                        return (<li>
                                                            <p className={styles.descriptionHeader}>{description.header}</p>
                                                            <p className={styles.descriptionBody}>{description.body}</p>
                                                        </li>)
                                                    })}
                                                </ul>
                                                {this.props.game.licence &&
                                                    <div className={styles.gameLicence}>
                                                        {this.props.game.licence}
                                                    </div>
                                                }
                                                {GamePackList.find((x) => x.gamePackInfo.gamesIncluded.find(i => i.gameId === this.props.game.gameId)) && <div className={styles.addons} >
                                                    <p className={styles.header}>Included In</p>
                                                    <div className={styles.addonsList}>
                                                        {
                                                            GamePackList.filter((x) => x.enabled && x.gamePackInfo.gamesIncluded.find(i => i.gameId === this.props.game.gameId)).map((g) => {
                                                                return <div className={styles.addonItem} onClick={() => this.goToAddon(g)}>
                                                                    <img src={g.meta.tinyCard} alt={`${g.name}`} className={styles.tinyCard} />
                                                                    <div className={styles.details} >
                                                                        <div className={styles.detailsWrapper} >
                                                                            {g.subNames.map(n => {
                                                                                return <p className={styles.addonHeader}> {n}</p>
                                                                            })}
                                                                            <p className={styles.addonPrice}>{this.props.pricing.countryCode === "GB" ? g.pricing.gb.display : g.pricing.us.display}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            })
                                                        }
                                                    </div>
                                                </div>}
                                                {this.props.game.addonIds.length > 0 && <div className={styles.addons} >
                                                    <p className={styles.header}>Add-ons</p>
                                                    <div className={styles.addonsList}>
                                                        {
                                                            GameAddonList.filter((x) => this.props.game.addonIds.includes(x.addonId)).map((g) => {
                                                                return <div className={styles.addonItem} onClick={() => this.goToAddon(g)}>
                                                                    <img src={g.meta.tinyCard} alt={`${g.name}`} className={styles.tinyCard} />
                                                                    <div className={ styles.details} >
                                                                        <div className={styles.detailsWrapper} >
                                                                        <p className={styles.addonHeader}> { g.name }</p>
                                                                        <p className={styles.addonPrice}>{this.props.pricing.countryCode === "GB" ? g.pricing.gb.display : g.pricing.us.display}</p>
                                                                    </div>
                                                                    </div>
                                                                </div>
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Container>
                    </section>
                    <section className={`${styles.band} ${styles.sm}`} style={this.getStoreAccentBackground()}>

                        <Container>
                            <div className="row text-center my-5" id="how-it-works">
                                <h2 className='text-uppercase mb-1'>Unsure about how it works?</h2>
                                <h4 className={`${styles.subTitle} text-uppercase`}>Playing a party game on your TV might sound like magic, but don't worry<br />it's as easy as 1-2-3.</h4>
                                <div className={`${styles.howItWorksBox} py-3`}>
                                    {
                                        howItWorksBlocks.map((x) => {
                                            return <div className={`${styles.howItWorksBlock} my-3`}>
                                                <div className={styles.number}>
                                                    <p>{x.number}</p>
                                                </div>
                                                <h3 className={styles.title}>{x.title}</h3>
                                                <p className={styles.description}>{x.description}</p>
                                            </div>
                                        })
                                    }
                                </div>
                            </div>
                            <div className='row text-center'>
                                <h2 className='text-uppercase'>More Games You'll Like</h2>


                                {GameList.filter((x) => this.props.game.gameUpsellIds.includes(x.gameId)).map(g => <div className='col-md-6 col-lg-4 text-center'><Game game={g} key={g.gameId} /></div>)}

                                {/* {GameList.filter(g => {g.gameId !== this.props.game.gameId }).map(g => <div className='col-md-6 col-lg-3 text-center'><Game game={g} key={g.gameId} /></div> )} */}

                                {/* {console.log(GameList.filter(g => {g.gameId !== this.props.game.gameId }))} */}
                            </div>
                        </Container>
                    </section>
                    <section className={`${styles.reviewsSection} ${styles.band} ${styles.large}`}>
                        <Slider className={styles.carousel} {...this.carouselSettings}>
                            {
                                Reviews.map((review) => {
                                    return <div className={`${styles.reviewWidth} container p-0`}>
                                        <div className={`${styles.reviewCard} card text-center mx-3 border-0 p-3`}>
                                            <h5 className={`${styles.reviewTitle} card-title`}>{review.name}</h5>
                                            <div className="row d-flex w-50 m-auto">
                                                {Array.from(Array(5), (e, i) => {
                                                    return <div className="col p-1">
                                                        <img className="img-fluid m-auto" style={{}} src={i <= review.stars - 1 ? StarFull : StarEmpty} />
                                                    </div>
                                                })}
                                            </div>
                                            <div className={`${styles.reviewBody} card-body`}>
                                                <p className={`${styles.reviewText} card-text`}>{review.description}</p>
                                            </div>
                                        </div>
                                    </div>
                                })
                            }
                        </Slider>
                    </section>
                </div>
                <Modal show={this.state.showHowToPlay} onHide={this.closeHelpModal} contentClassName={styles.rounded} >
                    <CloseButton style={{ position: "absolute" }} onClick={this.closeHelpModal} />
                    <Help />
                </Modal>
            </React.Fragment>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        pricing: state.pricing
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        addToCart: (game) => {
            dispatch(addToCart(game))
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ShopSingle));
