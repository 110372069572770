import {
    ROOM_CREATED,
    ROOM_STATE,
    ROOM_ERROR,
    ROOM_LEAVE,
    ROOM_JOIN,
    ROOM_START_GAME,
    ROOM_RECONNECT,
    ADD_PLAYER,
    REMOVE_PLAYER,
    UPDATE_PLAYER,
    ADD_AVATAR,
    UPDATE_AVATAR,
} from "actions/types";
import * as Colyseus from "colyseus.js";
import GameList from "constants/GameList";
import Store from "store/reduxstore";
import { UAParser } from "ua-parser-js";
import * as Sentry from "@sentry/react";
//import { datadogRum } from '@datadog/browser-rum';

console.log(process.env.REACT_APP_GAME_SERVER_URL);
const client = new Colyseus.Client(process.env.REACT_APP_GAME_SERVER_URL);


export const createRoom = (token, userId, isRoku = false, QRSessionID = null) => (dispatch) => {
    const reduxStore = Store.getState();

    const uniqueId = localStorage.getItem("uniqueId");
    var parser = new UAParser();
    const deviceDetails = parser.getResult();

    const networkDetails = {
        type: navigator?.connection?.type,
        effectiveType: navigator?.connection?.effectiveType,
        downlink: navigator?.connection?.downlink,
    };

    return client.create("game_city_room", { isHost: true, accessToken: token, userId: userId, email: reduxStore.auth.user?.email, uniqueId, deviceDetails, networkDetails, isRoku, QRSessionID}).then(room => {
        console.log("room created");
        console.log("Reconnection Token : ", room.reconnectionToken);
        console.log("QRSessionID : ", room.QRSessionID);
        //datadogRum.setUser({
        //    id: room.roomId,
        //    email: reduxStore.auth.user.email,
        //});

        room.send("update_host_token", { reconnectionToken: room.reconnectionToken });
        room.onStateChange.once((state) => {
            console.log("this is the first room state!", state);
            //room.send("host_joined_lobby");

            dispatch({ type: ROOM_CREATED, payload: { room, state } });
        });
        room.onStateChange((state) => {
            console.log(room.name, "has new state:", state);

            dispatch({ type: ROOM_STATE, payload: { roomState: state, } });
        });

        room.state.avatarList.onAdd((avatar, key) => {
            //console.log(avatar, "has been added at", key);
            dispatch({ type: ADD_AVATAR, payload: { avatar, } });

            avatar.onChange(() => {
                //dispatch({ type: UPDATE_PLAYER, payload: { player, changes, } });
            });
            avatar.listen("id", (value) => {
                dispatch({ type: UPDATE_AVATAR, payload: { avatar, field: "id", value, } });
            });
            avatar.listen("inUse", (value) => {
                dispatch({ type: UPDATE_AVATAR, payload: { avatar, field: "inUse", value, } });
            });
            avatar.listen("isActive", (value) => {
                dispatch({ type: UPDATE_AVATAR, payload: { avatar, field: "isActive", value, } });
            });
        });

        room.state.players.onAdd((player, key) => {
            console.log(player, "has been added at", key);
            dispatch({ type: ADD_PLAYER, payload: { player, } });

            player.onChange(() => {
                //dispatch({ type: UPDATE_PLAYER, payload: { player, changes, } });
            });
            player.listen("avatar", (value) => {
                dispatch({ type: UPDATE_PLAYER, payload: { player, field: "avatar", value, } });
            });
            player.listen("name", (value) => {
                dispatch({ type: UPDATE_PLAYER, payload: { player, field: "name", value, } });
            });
            player.listen("gameVote", (value) => {
                dispatch({ type: UPDATE_PLAYER, payload: { player, field: "gameVote", value, } });
            });
            player.listen("connected", (value) => {
                dispatch({ type: UPDATE_PLAYER, payload: { player, field: "connected", value, } });
            });
            player.listen("connectingTimer", (value) => {
                dispatch({ type: UPDATE_PLAYER, payload: { player, field: "connectingTimer", value, } });
            });
        });

        room.state.players.onRemove((player, key) => {
            console.log(player, "has been removed at", key);
            
            dispatch({ type: REMOVE_PLAYER, payload: { player, } });
        });

        room.onMessage("game_starting", (message) => {
            console.log("game_starting", "received on", room.name, message);
            let gameURL = GameList.find((x) => x.gameId === message.gameId).gameURL;
            dispatch({ type: ROOM_START_GAME, payload: { gameURL, gameId: message.gameId, reconnectionToken: room.reconnectionToken } });
        });

        room.onError((code, message) => {
            console.log(code, " error ", message);

            dispatch({ type: ROOM_ERROR, payload: { error: message, errorMessage: message, errorCode: code } });
        });

        room.onLeave((code) => {
            console.log(client.id, "left", room.name, "code", code);

            dispatch({ type: ROOM_LEAVE, payload: { errorCode:code } });
        });

        return { Status: "Success", room };

    }).catch((err, errorMessage) => {
        console.log("CREATE ERROR", err);
        dispatch({ type: ROOM_ERROR, payload: { error: err, message: err.message } });
        Sentry.captureMessage("Error Creating Room : " + JSON.stringify(err));
        return { Status: "Error", Message: "There was a problem creating your room." };
    });

};


export const joinRoom = (roomId, options) => (dispatch) => {

    const uniqueId = localStorage.getItem("uniqueId");
    options.uniqueId = uniqueId;

    var parser = new UAParser();
    options.deviceDetails = parser.getResult();

    const networkDetails = {
        type: navigator?.connection?.type,
        effectiveType: navigator?.connection?.effectiveType,
        downlink: navigator?.connection?.downlink,
    };
    options.networkDetails = networkDetails;

    return client.joinById(roomId, options).then(room => {
        console.log(room.sessionId, "joined", room.name);
        //this.storeSessionId(room.sessionId);
        //this.storeRoomId(roomId);
        console.log("Reconnection Token : ", room.reconnectionToken);

        room.send("update_player_token", { reconnectionToken: room.reconnectionToken });
        room.onStateChange.once((state) => {
            console.log("this is the first room state!", JSON.stringify(state));

            dispatch({ type: ROOM_JOIN, payload: { room, state } });
            //this.startLocationChecks();
        });

        room.onStateChange((state) => {
            console.log(room.name, "has new state:", state);

            dispatch({ type: ROOM_STATE, payload: { roomState: state, } });
        });

        room.state.avatarList.onAdd((avatar, key) => {
            console.log(avatar, "has been added at", key);
            dispatch({ type: ADD_AVATAR, payload: { avatar, } });

            avatar.onChange(() => {
                //dispatch({ type: UPDATE_PLAYER, payload: { player, changes, } });
            });
            avatar.listen("id", (value) => {
                dispatch({ type: UPDATE_AVATAR, payload: { avatar, field: "id", value, } });
            });
            avatar.listen("inUse", (value) => {
                dispatch({ type: UPDATE_AVATAR, payload: { avatar, field: "inUse", value, } });
            });
            avatar.listen("isActive", (value) => {
                dispatch({ type: UPDATE_AVATAR, payload: { avatar, field: "isActive", value, } });
            });
        });

        room.state.players.onAdd((player, key) => {
            console.log(player, "has been added at", key);
            dispatch({ type: ADD_PLAYER, payload: { player, } });

            player.onChange(() => {
                //dispatch({ type: UPDATE_PLAYER, payload: { player, changes, } });
            });
            player.listen("avatar", (value) => {
                dispatch({ type: UPDATE_PLAYER, payload: { player, field: "avatar", value, } });
            });
            player.listen("name", (value) => {
                dispatch({ type: UPDATE_PLAYER, payload: { player, field: "name", value, } });
            });
            player.listen("gameVote", (value) => {
                dispatch({ type: UPDATE_PLAYER, payload: { player, field: "gameVote", value, } });
            });
            player.listen("connected", (value) => {
                dispatch({ type: UPDATE_PLAYER, payload: { player, field: "connected", value, } });
            });
            player.listen("connectingTimer", (value) => {
                dispatch({ type: UPDATE_PLAYER, payload: { player, field: "connectingTimer", value, } });
            });
        });

        room.state.players.onRemove((player, key) => {
            console.log(player, "has been removed at", key);

            dispatch({ type: REMOVE_PLAYER, payload: { player, } });
        });

        room.onMessage("game_starting", (message) => {
            console.log("game_starting", "received on", room.name, message);
            let gameURL = GameList.find((x) => x.gameId == message.gameId).gameURL;
            dispatch({ type: ROOM_START_GAME, payload: { gameURL, gameId: message.gameId, reconnectionToken: room.reconnectionToken } });
        });

        room.onError((code, message) => {
            console.log(code, " error ", message);
            dispatch({ type: ROOM_ERROR, payload: { message, code } });
        });

        room.onLeave((code) => {
            console.log(client.id, "left", room.name);

            dispatch({ type: ROOM_LEAVE, payload: { } });
        });

        return { Status: "Success", room };
    }).catch((err, errorMessage) => {
        console.log("JOIN ERROR", err);

        const message = err.message ? err.message : "An error occured.";
        dispatch({ type: ROOM_ERROR, payload: { error: err, message, } });
        Sentry.captureMessage("Error Joining Room : " + JSON.stringify(err));

        return { Status: "Error", Message: message};
    });

};

export const reconnectToRoom = (token) => (dispatch) => {
    return client.reconnect(token).then(room => {
        console.log(room.sessionId, "joined", room.name);
        //this.storeSessionId(room.sessionId);
        //this.storeRoomId(roomId);
        console.log("Reconnection Token : ", room.reconnectionToken);


        room.onStateChange.once((state) => {
            console.log("this is the first room state!", state);

            if (room.state.host.id === room.sessionId) {
                room.send("update_host_token", { reconnectionToken: room.reconnectionToken });
                room.send("host_joined_lobby", {});
            } else {
                room.send("update_player_token", { reconnectionToken: room.reconnectionToken });
            }

            dispatch({ type: ROOM_RECONNECT, payload: { room, state, } });
            //this.startLocationChecks();
        });

        room.onStateChange((state) => {
            console.log(room.name, "has new state:", state);

            dispatch({ type: ROOM_STATE, payload: { roomState: state, } });
        });

        room.state.avatarList.onAdd((avatar, key) => {
            //console.log(avatar, "has been added at", key);
            dispatch({ type: ADD_AVATAR, payload: { avatar, } });

            avatar.onChange(() => {
                //dispatch({ type: UPDATE_PLAYER, payload: { player, changes, } });
            });
            avatar.listen("id", (value) => {
                dispatch({ type: UPDATE_AVATAR, payload: { avatar, field: "id", value, } });
            });
            avatar.listen("inUse", (value) => {
                dispatch({ type: UPDATE_AVATAR, payload: { avatar, field: "inUse", value, } });
            });
            avatar.listen("isActive", (value) => {
                dispatch({ type: UPDATE_AVATAR, payload: { avatar, field: "isActive", value, } });
            });
        });

        room.state.players.onAdd((player, key) => {
            console.log(player, "has been added at", key);
            dispatch({ type: ADD_PLAYER, payload: { player, } });

            player.onChange(() => {
                //dispatch({ type: UPDATE_PLAYER, payload: { player, changes, } });
            });
            player.listen("avatar", (value) => {
                dispatch({ type: UPDATE_PLAYER, payload: { player, field: "avatar", value, } });
            });
            player.listen("name", (value) => {
                dispatch({ type: UPDATE_PLAYER, payload: { player, field: "name", value, } });
            });
            player.listen("gameVote", (value) => {
                dispatch({ type: UPDATE_PLAYER, payload: { player, field: "gameVote", value, } });
            });
            player.listen("connected", (value) => {
                dispatch({ type: UPDATE_PLAYER, payload: { player, field: "connected", value, } });
            }); 
            player.listen("connectingTimer", (value) => {
                dispatch({ type: UPDATE_PLAYER, payload: { player, field: "connectingTimer", value, } });
            });
        });

        room.state.players.onRemove((player, key) => {
            console.log(player, "has been removed at", key);

            dispatch({ type: REMOVE_PLAYER, payload: { player, } });
        });

        room.onMessage("game_starting", (message) => {
            console.log("game_starting", "received on", room.name, message);
            let gameURL = GameList.find((x) => x.gameId == message.gameId).gameURL;
            dispatch({ type: ROOM_START_GAME, payload: { gameURL, gameId: message.gameId, reconnectionToken: room.reconnectionToken } });
        });

        room.onError((code, message) => {
            console.log(code, " error ", message);
            dispatch({ type: ROOM_ERROR, payload: { message, code } });
        });

        room.onLeave((code) => {
            console.log(client.id, "left", room.name);

            dispatch({ type: ROOM_LEAVE, payload: {} });
        });

        return { Status: "Success", room, };
    }).catch((err, errorMessage) => {
        console.log("JOIN ERROR", err);

        const message = err.message ? err.message : "An error occured.";
        dispatch({ type: ROOM_ERROR, payload: { error: err, message} });

        Sentry.captureMessage("Error Reconnecting to Room : " + JSON.stringify(err));
        return { Status: "Error", Message: message, };
    });

};


export const leaveRoom = () => (dispatch) => {
    const reduxStore = Store.getState();
    reduxStore.room.room.leave(true);

    dispatch({ type: ROOM_LEAVE, payload: { } });
};