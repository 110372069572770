import React, { Component } from 'react';
import { connect, } from 'react-redux';
import { CloseButton, Container, Modal, Spinner, Carousel, } from 'react-bootstrap';
import { withRouter } from './withRouter';
import { Helmet } from 'react-helmet';
import Lottie from 'react-lottie';
import ReactPlayer from 'react-player';

import GameList from "constants/GameList";
import Reviews from "constants/reviews";
import styles from './ShopGamePackStyles.module.scss';
import Game from './Games/Game';
import { addToCart } from 'actions/cart';

import time from 'animations/time.gif';
import players from 'animations/players.gif';
import age from 'animations/age.gif';
import GiftBox from "images/Promotions/02642 Christmas Present.png";

// Slick Carousel
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./carouselStyles.css";
import Slider from "react-slick";
import StarFull from "images/template/full-star.png";
import StarEmpty from "images/template/empty-star.png";
import VideoThumbnail from "videos/video-thumbnail.png";
import { Link } from 'react-router-dom';

const howItWorksBlocks = [
    {
        number: 1,
        title: "GET IT ON THE BIG SCEEN",
        //"description": "Only one person needs to own the games. They will be 'party leader' and it's their job to get Game.City up on the biggest screen they can. Use Teams, Zoom or Discord if you want to play online. Use a smart TV to open it with a browser or connect up with a laptop and HDMI. Once you have achieved that, simply log in to your account."
        "description": "Open Game.City on a TV or connect a laptop. Log in!"
    },
    {
        number: 2,
        title: "GATHER SOME FRIENDS",
        //description: "Gather some friends, either online or in the same room. Once you have Game.City on the screen and you're logged in, players simply need to scan the QR code to join in, or head to Game.City and join using the Room Code.",
        description: "Scan the QR code or enter the Room Code at Game.City.",
    },
    {
        number: 3,
        title: "PLAY AND HAVE FUN",
        //description: "Once joined, choose a potato avatar, change your nickname and then choose a game. Get playing and have a great time. All our online games are extra family friendly so everyone can enjoy!"
        description: "Choose an avatar, pick a game, and enjoy!"
    }
];

class ShopGamePack extends Component {
    static displayName = ShopGamePack.name;

    constructor(props) {
        super(props);

        this.state = {
            // game: {},
            showHowToPlay: false,
        };
    }

    carouselSettings = {
        dots: true,
        dotsClass: "slick-dots slick-thumb",
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: true,
        centerMode: true,
        // nextArrow: <SampleNextArrow />,
        // prevArrow: <SamplePrevArrow />,
        customPaging: function (i) {
            return (
                <div className="carousel-dot">

                </div>
            );
        },
    };


    componentDidMount() {
        window.scrollTo(0, 0);
    }


    getStoreAccentBorder() {
        return {
            backgroundColor: this.props.game?.meta.storeAccentColour,
            border: `10px solid ${this.props.game?.meta.storeAccentColour}`
        }
    }

    getStoreAccentBackground() {
        return {
            backgroundColor: this.props.game?.meta.storeAccentColour,
            border: `10px solid ${this.props.game?.meta.storeAccentColour}`
        }
    }

    getStoreAccentImageBorder() {
        return {
            border: `2px solid ${this.props.game?.meta.storeAccentColour}`
        }
    }

    getStoreBackground() {
        return {
            backgroundImage: `url(${this.props.game?.meta.storeBackground})`
        }
    }

    getStoreInfoBackground() {
        return {
            backgroundColor: `${this.props.game?.meta.storeInfoBackground}`
        }
    }

    addGameToCart(game) {
        this.props.addToCart(game);
    }

    getIncludedGames = () => {
        let includedGames = GameList.filter((x) => this.props.game?.gamePackInfo.gamesIncluded.find((y) => y.gameId === x.gameId));

        return includedGames
    }

    getMobileMobileImages = () => {
        const groupSize = 2;
        let arr = [];
        for (let i = 0; i < this.props.game?.gamePackInfo.gamesIncluded.length; i += groupSize) {
            const group = this.props.game?.gamePackInfo.gamesIncluded.slice(i, i + groupSize);

            arr.push(<div className={`${styles.imageRow} ${styles.doubleColRow}`}>
                {
                    group.map((g) => {
                        return <div style={this.getStoreAccentImageBorder()}>
                            <img src={g.mobile1}></img>
                        </div>
                    })
                }
            </div>);
        }

        return arr;
    }

    getPrice() {
        let countryCode = this.props.pricing.countryCode;

        if (countryCode == "GB") {
            return this.props.game?.pricing.gb.display;
        } else {
            return this.props.game?.pricing.us.display;
        }
    }

    getStrikethroughPrice = () => {
        let games = this.getIncludedGames();
        let price = 0;
        let currency;
        let currencyCode = this.props.pricing.countryCode;
        games.forEach((g) => {
            let gamePrice = currencyCode == "GB" ? g.pricing.gb : g.pricing.us;
            if (gamePrice) {
                price += gamePrice.raw;
                currency = gamePrice.display.slice(0, 1);
            }
        });
        if (price === 0) {
            return null;
        }
        return currency + price;
    }

    getStars() {
        let stars = [];
        for (let i = 0; i < this.props.game?.gamePackInfo.stars; i++) {
            stars.push(<img className={styles.star} src={StarFull} alt="Star" />)
        }
        for (let i = 0; i < 5 - this.props.game?.gamePackInfo.stars; i++) {
            stars.push(<img className={styles.star} src={StarEmpty} alt="Star" />)
        }
        return stars;
    }

    closeHelpModal = () => {
        this.setState({ showHowToPlay: false, });
    }

    getPackHero = () => {
        return {
            backgroundImage: `url(${this.props.game?.meta.packHero})`
        }
    }

    newLineText = (text) => {
        return text.split('\n').map((str) => {
            return <>{str}<br /></>
        });
    }

    scrollIntoView = (id) => {
        document.getElementById(id).scrollIntoView({ behavior: "smooth" });
    }

    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <title>Game City - {this.props.game?.name}</title>
                    <link rel="canonical" href={`https://game.city/${this.props.game?.storeSlug}`} />
                    <meta name="description" content={this.props.game?.description} />
                </Helmet>
                <div className={styles.pageShopSingle} style={this.getStoreBackground()}>
                    <section className={`${styles.band} ${styles.noTop}`}>
                        <Container className="text-center">
                            <div className='row'>
                                <Carousel className={styles.hero} controls={false}>
                                    {
                                        this.props.game?.meta.heros.map((h) => {
                                            return <Carousel.Item className={styles.carouselItem}>
                                                <img src={h} alt="Hero" className={styles.carouselImage} />
                                            </Carousel.Item>
                                        })
                                    }
                                </Carousel>
                            </div>
                            <div className='row text-center mt-3'>
                                <h2 className='text-uppercase mb-0'>{this.newLineText(this.props.game.gamePackInfo.title)}</h2>
                                <p style={{ color: "white", fontSize: "20px" }}>{this.props.game.gamePackInfo.subTitle}</p>
                            </div>
                            <div className={`row justify-content-center ${styles.gameInfoContainer}`}>
                                {/*<div className='col-lg-8 order-2 order-lg-1 text-center'>*/}
                                {/*    <div className={`${styles.previewImages} d-none d-lg-block`} style={{ ...this.getStoreAccentBorder(), ...this.getStoreAccentBackground() }}>*/}
                                {/*        {*/}
                                {/*            this.props.game.gamePackInfo?.gamesIncluded.map((g, i) => {*/}
                                {/*                return <div className={`${styles.imageRow} ${i % 2 == 0 && styles.reverse}`}>*/}
                                {/*                    <div style={this.getStoreAccentImageBorder()}>*/}
                                {/*                        <img className={styles.mobilePreview} src={g.mobile1}></img>*/}
                                {/*                    </div>*/}
                                {/*                    <div style={this.getStoreAccentImageBorder()}>*/}
                                {/*                        <img className={styles.desktopPreview} src={g.desktop1}></img>*/}
                                {/*                    </div>*/}
                                {/*                </div>*/}
                                {/*            })*/}
                                {/*        }*/}
                                {/*    </div>*/}
                                {/*    <div className={`${styles.previewImages} ${styles.mobile} d-block d-lg-none`} style={this.getStoreAccentBorder()}>*/}
                                {/*        {*/}
                                {/*            this.props.game.gamePackInfo?.gamesIncluded.map((g, i) => {*/}
                                {/*                return <div className={`${styles.imageRow} ${styles.singleDesktopRow}`}>*/}
                                {/*                    <div style={this.getStoreAccentImageBorder()}>*/}
                                {/*                        <img src={g.desktop1}></img>*/}
                                {/*                    </div>*/}
                                {/*                </div>*/}
                                {/*            })*/}
                                {/*        }*/}
                                {/*        {*/}
                                {/*            this.getMobileMobileImages()*/}
                                {/*        }*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                                <div className={`${styles.gamesList} col-xl-4 col-lg-5 col-md-6`}>
                                    {
                                        this.props.game?.gamePackInfo.gamesIncluded.map((g) => {
                                            return <img src={g.tinyCard} alt={`${g.name}`} onClick={() => this.props.navigate(g.storeSlug)} className={styles.tinyCard} />
                                        })
                                    }
                                </div>
                                <div className='col-xl-4 col-lg-5 col-md-6 order-1 order-lg-2'>
                                    {/*<div className={styles.giftHint} >*/}
                                    {/*    <img src={GiftBox} alt="gift" />*/}
                                    {/*    <p>PERFECT STOCKING FILLER</p>*/}
                                    {/*    <img src={GiftBox} alt="gift" />*/}
                                    {/*</div>*/}
                                    <div className={styles.gameInfoWrapper} style={this.getStoreInfoBackground()}>
                                        <div className={styles.strikeThroughPrice}><strong><span>{this.getStrikethroughPrice() || <Spinner animation="border" size="sm" />}</span></strong></div>

                                        <p className={styles.gamePrice}>{this.getPrice() || <Spinner animation="border" />}</p>
                                        <div className={styles.starRow}>
                                            {
                                                this.props.game?.gamePackInfo.stars > 0 &&
                                                this.getStars()
                                            }
                                            <span className={styles.starSum} >({Reviews.length})</span>
                                        </div>
                                        {/*<br />*/}
                                        <button className={styles.button} onClick={() => this.addGameToCart(this.props.game)}>{this.state.loading ? <Spinner animation="border" /> : "Add To Basket"}</button>
                                        <br />
                                        <button className={`${styles.button} ${styles.medium} ${styles.alternate}`} onClick={() => this.setState({ showHowToPlay: true, }) }>What is Game.City?</button>
                                        <br />
                                        <Link onClick={() => this.scrollIntoView("how-it-works")} className={styles.linkText}><strong>If you are unsure about how it <br /> works click here</strong></Link>

                                        <ul className={styles.gameInfo}>
                                            <li>
                                                <div className={styles.icon}>
                                                    <img src={time} />
                                                </div>
                                                <span>{this.props.game.gamePackInfo?.avgRoundLengthText}</span>
                                            </li>
                                            <li>
                                                <div className={styles.icon}>
                                                    <img src={players} />
                                                </div>
                                                <span>{this.props.game.gamePackInfo?.minPlayers}-8 Players</span>
                                            </li>
                                            <li>
                                                <div className={styles.icon}>
                                                    <img src={age} />
                                                </div>
                                                <span>
                                                    Age {this.props.game.gamePackInfo?.minAge}+
                                                </span>
                                            </li>
                                        </ul>

                                        <hr></hr>

                                        <p className={`${styles.gameDescription} text-start`}><strong>{this.props.game.description}</strong></p>
                                    </div>
                                </div>
                            </div>
                        </Container>
                    </section>
                    <header className={styles.smallBanner}>Works perfectly in all English speaking countries!</header>
                    <section className={`${styles.band} ${styles.sm}`} style={this.getStoreAccentBackground()}>
                        <Container>
                            <div className={`row text-center justify-content-center`}>
                                <h2 className='text-uppercase'>About the games included</h2>
                                {
                                    this.getIncludedGames().map(g => <div className={`${styles.gameDetails} col-md-6 col-lg-4 text-center p-3`}>
                                        <div className={styles.gameDetailsInner}>
                                            <img className={styles.icon} src={g.meta.tinyCard} alt={`${g.name}-info-icon`} />
                                            <p className={styles.description}>{this.newLineText(g.description)}</p>
                                            <div className={`row mt-1 p-4 ${styles.imageBlock}`}>
                                                <div style={{ backgroundImage: `url(${g.meta.previewImages.desktop1})` }} className={`col-7 ${styles.img}`}>
                                                    <img src={g.meta.previewImages.desktop1} alt={`${g.name}-preview-desktop-1`} />
                                                </div>
                                                <div style={{ backgroundImage: `url(${g.meta.previewImages.mobile1})` }} className={`col-3 ${styles.img}`}>
                                                    <img src={g.meta.previewImages.mobile1} alt={`${g.name}-preview-mobile-1`} />
                                                </div>
                                                <div style={{ backgroundImage: `url(${g.meta.previewImages.mobile2})` }} className={`col-3 ${styles.img}`}>
                                                    <img src={g.meta.previewImages.mobile2} alt={`${g.name}-preview-mobile-2`} />
                                                </div>
                                                <div style={{ backgroundImage: `url(${g.meta.previewImages.desktop2})` }} className={`col-7 ${styles.img}`}>
                                                    <img src={g.meta.previewImages.desktop2} alt={`${g.name}-preview-desktop-2`} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>)
                                }
                            </div>
                            <div className="row text-center my-5" id="how-it-works">
                                <h2 className='text-uppercase mb-1'>Unsure about how it works?</h2>
                                <h4 className={`${styles.subTitle} text-uppercase`}>Playing a party game on your TV might sound like magic, but don't worry - it's as easy as 1-2-3.</h4>
                                <div className={`${styles.howItWorksBox} py-3`}>
                                    {
                                        howItWorksBlocks.map((x) => {
                                            return <div className={`${styles.howItWorksBlock} my-3`}>
                                                <div className={styles.number}>
                                                    <p>{x.number}</p>
                                                </div>
                                                <h3 className={styles.title}>{x.title}</h3>
                                                <p className={styles.description}>{x.description}</p>
                                            </div>
                                        })
                                    }
                                </div>
                            </div>
                            <div className='row text-center'>
                                <h2 className='text-uppercase'>buy them individually</h2>
                                {
                                    this.getIncludedGames().map(g => <div className='col-md-6 col-lg-4 text-center'><Game game={g} key={g.gameId} /></div>)
                                }
                            </div>
                        </Container>
                    </section>
                    <section className={`${styles.reviewsSection} ${styles.band} ${styles.large}`}>
                        <Slider className={styles.carousel} {...this.carouselSettings}>
                            {
                                Reviews.map((review) => {
                                    return <div className={`${styles.reviewWidth} container p-0`}>
                                        <div className={`${styles.reviewCard} card text-center mx-3 border-0 p-3`}>
                                            <h5 className={`${styles.reviewTitle} card-title`}>{review.name}</h5>
                                            <div className="row d-flex w-50 m-auto">
                                                {Array.from(Array(5), (e, i) => {
                                                    return <div className="col p-1">
                                                        <img className="img-fluid m-auto" style={{}} src={i <= review.stars - 1 ? StarFull : StarEmpty} />
                                                    </div>
                                                })}
                                            </div>
                                            <div className={`${styles.reviewBody} card-body`}>
                                                <p className={`${styles.reviewText} card-text`}>{review.description}</p>
                                            </div>
                                        </div>
                                    </div>
                                })
                            }
                        </Slider>
                    </section>
                </div>
                <Modal show={this.state.showHowToPlay} onHide={this.closeHelpModal} contentClassName={styles.rounded} centered dialogClassName="howToPlayModal" >
                    <CloseButton style={{ position: "absolute", zIndex: 2, top: "15px", left: "15px" }} onClick={this.closeHelpModal} />
                    <Modal.Body style={{ padding: "10px" }}>
                        <div className={styles.thumbnail}>
                        <img style={{ width: "100%", height: "100%" }} src={VideoThumbnail} />
                        </div>
                        <ReactPlayer url={this.props.game.meta.video} controls id='game-city-video' width={"100%"} height="auto" style={{ position:"relative", zIndex: 1,}}></ReactPlayer> 
                    </Modal.Body>
                </Modal>
            </React.Fragment>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        pricing: state.pricing
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        addToCart: (game) => {
            dispatch(addToCart(game))
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ShopGamePack));
